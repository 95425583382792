import styled, { css, keyframes } from 'styled-components'
import React, {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react'
import { formatUnits } from 'ethers/lib.esm/utils'
import DownArrow from '../../../images/downarrow.svg'
import SfiBox from '../../../images/sfi-box.svg'
import { Image } from '../shared/Image'
import { PoolCardFullContent } from './PoolCardFullContent'
import { trimDecimalStringToPrecision } from '../../../utils/trimDecimalStringToPrecision'
import { useSmoothScrollTo } from '../../../utils/useSmoothScrollTo'
import { useWindowSize } from '../../../utils/useWindowSize'
import { bn } from '../../../utils/bn'
import { AppStateActionName, useAppStateReducer } from '../../../AppState'
import { useSfiPrice } from '../../../hooks/useSfiPrice'
import { useWeb3ReactPlus } from '../../../Web3ReactPlus/Web3ReactPlusProvider'
import { usePendingSfi } from '../../../hooks/usePendingSfi'
import { useAmountStaked } from '../../../hooks/useAmountStaked'
import { useTokenIcons } from '../../../hooks/useTokenIcons'
import { useIsCardOpen } from '../../../hooks/useIsCardOpen'
import { useBlockNumber } from '../../../Web3ReactPlus/hooks/useBlockNumber'
import { useSaffronStakingProtocolInfo } from '../../../hooks/useSaffronStakingProtocolInfo'
import { EarningsModal } from '../modals/Earnings'
import { usePendingUsd } from '../../../hooks/usePendingUsd'

const CLOSED_CARD_HEIGHT = 69

function PoolCard({ poolInfo }) {
  const [_, dispatch] = useAppStateReducer()
  const { chainId } = useWeb3ReactPlus()

  const [earningsModalOpen, setEarningsModalOpen] = useState(false)

  function toggleEarningsModal(e) {
    setEarningsModalOpen(!earningsModalOpen)
    e.stopPropagation()
  }

  const [cardScrollHeight, setCardScrollHeight] = useState(CLOSED_CARD_HEIGHT)
  const cardRef = useSmoothScrollTo(`#${poolInfo.id}`, `scrollToPool${poolInfo.id}`)
  const cardContentRef = useRef()
  const [pendingUsd] = usePendingUsd(poolInfo.id)
  const windowSize = useWindowSize()

  const [token1Svg, token2Svg] = useTokenIcons(poolInfo)
  const isCardOpen = useIsCardOpen(poolInfo.id)

  // Get scroll height of div (including hidden content) so we can reveal the right amount
  // Update on resize to check if things reshuffled upon responding
  useLayoutEffect(() => {
    setCardScrollHeight(CLOSED_CARD_HEIGHT + cardContentRef.current.scrollHeight)
  }, [windowSize, cardContentRef.current])

  function toggleOpen() {
    dispatch({
      type: AppStateActionName.TogglePoolCard,
      payload: { chainId, poolId: poolInfo.id },
    })
  }

  const buttonText = isCardOpen ? 'Close' : 'Deposit/Withdraw'

  return (
    <Card open={isCardOpen} scrollHeight={cardScrollHeight} ref={cardRef}>
      <CardTop onClick={toggleOpen}>
        <IconColumn>
          <IconContainer>
            <Icon>
              <Image width="24px" height="24px" src={token1Svg} />
            </Icon>
            {poolInfo.stakedToken?.underlyingToken1
              ? (
                <Icon overlap>
                  <Image width="24px" height="24px" src={token2Svg} />
                </Icon>
              )
              : null}
          </IconContainer>
        </IconColumn>
        <TitleColumn>
          <TitleContainer>
            <ColumnValue>{poolInfo.pairName}</ColumnValue>
            <ColumnTitle>
              {poolInfo.emoji}
              {' '}
              {poolInfo.platform}
            </ColumnTitle>
          </TitleContainer>
        </TitleColumn>
        <EarningsColumn hide={!pendingUsd}>
          <TitleContainer hoverBox onClick={toggleEarningsModal}>
            <ColumnTitle>Earnings</ColumnTitle>
            <GradientColumnValue>{pendingUsd}</GradientColumnValue>
          </TitleContainer>
        </EarningsColumn>
        <InfoColumn>
          <TitleContainer>
            <ColumnTitle>APR</ColumnTitle>
            <ColumnValue>
              {/** TODO: revert 0 to poolInfo.apr */}
              {trimDecimalStringToPrecision(0, 2, 5)}
              %
            </ColumnValue>
          </TitleContainer>
        </InfoColumn>
        <InfoColumn hideOnMobile>
          <TitleContainer>
            <ColumnTitle>TVL</ColumnTitle>
            <ColumnValue>
              $
              {trimDecimalStringToPrecision(poolInfo.tvlUsd, 0)}
            </ColumnValue>
          </TitleContainer>
        </InfoColumn>
        <DepositColumn open={isCardOpen}>
          <Button className="dropdown-button">{buttonText}</Button>
          <Image src={DownArrow} />
        </DepositColumn>
      </CardTop>
      <PoolCardFullContent poolInfo={poolInfo} innerRef={cardContentRef} />
      <EarningsModal poolInfo={poolInfo} isOpen={earningsModalOpen} onRequestClose={toggleEarningsModal} />
    </Card>
  )
}

export default PoolCard

const Card = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  ${({ open, scrollHeight }) => {
    if (!open) {
      return css`
        height: ${CLOSED_CARD_HEIGHT}px
      `
    }
    return css`
        height: ${scrollHeight}px;
      `
  }};
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;
  border-radius: 8px;
  background-color: white;
  margin: 0 auto 12px;
  overflow: hidden;
`

const IconContainer = styled.div`
  width: 50px;
  margin-top: 5px;
  display: inline-flex;
`

const Icon = styled.div`
  margin-left: ${(props) => (props.overlap ? '-5px' : '0')};
`

const CardTop = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 15px;
  width: 100%;
  height: ${CLOSED_CARD_HEIGHT}px;
  &:hover .dropdown-button::before {
    opacity: 1;
  }
`
const TitleContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  
  ${({ hoverBox }) => {
    if (hoverBox) {
      return css`
        padding: 5px 7px;
        background-color: transparent;
        border-radius: 4px;
        transition: background-color 0.1s ease-in-out;
        &:hover {
          background-color: #c4473638;
        }
      `
    }
  }}
`

const IconColumn = styled.div`
  width: 50px;
  display: inline-flex;
  text-align: left;
  align-items: center;
`

const TitleColumn = styled.div`
  width: 17%;
  //display: inline-flex;
  //text-align: left;
  align-items: center;
  margin-right: auto;
  white-space: nowrap;
`

const InfoColumn = styled.div`
  width: 17%;
  ${({ hideOnMobile = false }) => hideOnMobile && css`
    @media (max-width: 800px) {
      display: none;
    }
  `}

`

const EarningsColumn = styled.div`
  width: 14%;
  display: flex;
  gap: 10px;
  ${({ hide }) => (hide ? css`
  visibility: hidden;
  ` : '')}
  @media (max-width: 800px) {
    visibility: hidden;
    width: 0%;
  }
`

const DepositColumn = styled.div`
  display: inline-flex;
  margin-left: auto;
  font-weight: 600;
  
  @media (max-width: 800px) {
    margin-left: 5px;
    button {
      display: none;
    }
  }
  
  img {
    transition: 0.1s linear;
    ${({ open }) => {
    if (open) {
      return css`
        transform: rotate(-180deg);
      `
    } return css`
        transform: rotate(0);
      `
  }
}
`

// Texts

const ColumnTitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: #545463;
  text-align: left;

  display: flex;
  gap: 7px;
  align-items: center;
`

const EarningsIcon = styled.img`
  shape-rendering: crispedges;

  &:hover {
    filter: brightness(0.8) sepia(1) hue-rotate(320deg) saturate(5);
  }
`

const ColumnValue = styled.span`
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  color: #0f1621;
`

const CycleSfiColors = keyframes`
  0% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
`

const GradientColumnValue = styled.span`
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  background-image: linear-gradient(to right, #c44536, #c44536, #c48c36, #c44536, #c44536);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;

  background-size: 400%;
  background-position: right;

  animation-name: ${CycleSfiColors};
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`

// Button

const Button = styled.button`
  font-size: 15px;
  width: 162px;
  height: 32px;
  border: none;
  border-radius: 100px;
  background-image: linear-gradient(to right, #c44536, #c48c36);
  color: #ffffff;
  font-weight: 600;
  margin-right: 15px;
  cursor: pointer;
  position: relative;
  z-index: 1;

  ::before {
    border-radius: 100px;
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to left, #c44536, #c48c36);;
    z-index: -1;
    transition: opacity 0.15s linear;
    opacity: 0;
  }
`
